const DataType = 
{
    CATEGORICAL :  "CATEGORICAL", 
    FREQUENCY : "FREQUENCY", 
    NUMERIC : "NUMERIC",
    TREATMENT : "TREATMENT",
    MULTICHOICE : "MULTICHOICE",
    KEYNESS : "KEYNESS",
    WORDCLOUD : "WORDCLOUD",
    UMBRELLA : "UMBRELLA"
}

export default DataType;